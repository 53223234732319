import React from 'react';
import { graphql, Link } from 'gatsby';
import { makeStyles, Typography } from '@material-ui/core';

import Hero from 'storyblok/components/Hero';
import PageWrapper from 'components/PageWrapper';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import ViewMenusButton from 'components/ViewMenusButton';

const useStyles = makeStyles(theme => ({
	actionContainer: {
		marginTop: theme.sizes.gutter,
	},
	separator: {
		border: 'none',
		borderTop: `1px solid white`,
		margin: `${theme.sizes.componentSpacing / 2}px 0`,
	},
	verticalSeparation: {
		marginBottom: theme.sizes.gutter,
	},
}));

const PartnerLandingPage = ({
	data: {
		allPartner: { nodes },
		allSporkLocation,
		site: { siteMetadata },
	},
}) => {
	const classes = useStyles();
	const [partner] = nodes;

	return (
		<PageWrapper
			title={partner.name}
			description={partner.description}
			ogImage={{
				alt: partner.name,
				height: partner.localPhoto.childImageSharp.fixed.height,
				src: `${siteMetadata.url}${partner.localPhoto.childImageSharp.fixed.src}`,
				width: partner.localPhoto.childImageSharp.fixed.width,
			}}
			preContent={
				<Hero
					blok={{
						backgroundImage:
							partner.localPhoto.childImageSharp.fluid,
						headline: partner.name,
						height: 'medium',
					}}
					additionalContent={
						<div className={classes.actionContainer}>
							{allSporkLocation.nodes.length > 1 && (
								<Typography
									className={classes.verticalSeparation}
								>
									Locations:{' '}
									{partner.SporkLocations.map(
										({ name }) => name
									).join(', ')}
								</Typography>
							)}
							{partner.PartnerTags.length > 0 && (
								<Typography
									className={classes.verticalSeparation}
								>
									Tags:{' '}
									{partner.PartnerTags.map(
										({ name }) => name
									).join(', ')}
								</Typography>
							)}
							<Typography className={classes.verticalSeparation}>
								Meals:{' '}
								{partner.MealTypes.map(({ name }) => name).join(
									', '
								)}
							</Typography>
							<Typography
								component="div"
								className={classes.verticalSeparation}
								dangerouslySetInnerHTML={{
									__html: partner.description,
								}}
							/>
							<hr className={classes.separator} />
							<div className={classes.verticalSeparation}>
								<PrimaryButton
									component={Link}
									to={`/get-started/?restaurant=${partner.landingPageSlug}`}
								>
									Get Started with {partner.name}
								</PrimaryButton>
							</div>
							<ViewMenusButton
								partner={partner}
								showGetStartedButton={true}
								showPartnerInDialog={false}
							>
								{onClick => (
									<SecondaryButton onClick={onClick}>
										View Sample Menus
									</SecondaryButton>
								)}
							</ViewMenusButton>
						</div>
					}
				/>
			}
		/>
	);
};

export const query = graphql`
	query GetPartnerForLandingPage($landingPageSlug: String!) {
		allPartner(filter: { landingPageSlug: { eq: $landingPageSlug } }) {
			nodes {
				id
				name
				description
				isFeaturedPartner
				isNewPartner
				landingPageSlug
				localPhoto {
					childImageSharp {
						fluid(maxWidth: 1500) {
							...GatsbyImageSharpFluid_tracedSVG
						}
						fixed(width: 1200, height: 630) {
							height
							src
							width
						}
					}
				}
				localLogo {
					childImageSharp {
						fixed(width: 75) {
							...GatsbyImageSharpFixed_tracedSVG
						}
					}
				}
				MealTypes {
					id
					name
				}
				PartnerTags {
					id
					name
				}
				SporkLocations {
					name
				}
			}
		}
		allSporkLocation {
			nodes {
				id
			}
		}
		site {
			siteMetadata {
				title
				url
			}
		}
	}
`;

export default PartnerLandingPage;
